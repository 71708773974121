* {
    margin: 0;
    padding: 0;
}

body {
    overflow: hidden;
}

.hero-container {
    width: 100%;
    height: 100vh;

    background-color: black;

    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    z-index: -100;

    main.hero {
        position: relative;

        width: 70%;
        height: fit-content;

        // border: 2px solid white;

        .profile-image-tilt {
            position: absolute;
            bottom: 0;
            right: 0;

            z-index: -10;
        }

        .profile-image {
            position: absolute;
            bottom: 0;
            right: 0;

            width: 60vmin;

            z-index: -1;
        }

        .dot-grid-1-tilt {
            position: absolute;

            left: -20px;
            top: -20px;

            z-index: 20;
        }

        .dot-grid-2-tilt {
            position: absolute;

            bottom: -20px;
            right: -20px;

            z-index: 20;
        }
    }
}