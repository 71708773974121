@import "../../css/colors";

@import "../../fonts/Thunder-BlackLC.css";
@import "../../fonts/Thunder-HC.css";


.full-name {
    color: $white;
    font-size: 40vmin;
    line-height: 65%;

    padding: 14px 0 50px 0;
}

.first-name {
    font-family: 'ThunderBlackLC';
    font-weight: 900;
}

.last-name {
    font-family: 'ThunderHC';
    font-weight: 400;
}