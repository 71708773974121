@import "../../css/colors";

@import "../../fonts/Thunder-BoldLC.css";


.description-text {
    color: $white;

    font-family: 'ThunderBoldLC';
    font-style: normal;
    font-weight: 700;

    font-size: 6vmin;

    padding: 10px 4px;

    text-transform: uppercase;
}