@import "../../css/colors";


.dots {
    width: fit-content;

    display: flex;
    flex-direction: column;

    gap: 4px;

    transition: all 0.5s cubic-bezier(0.18, 0.89, 0.32, 1.71);

    &:hover {
        gap: 8px;

        .dots-row {
            gap: 8px;
        }
    }

    &-row {
        display: flex;
        flex-direction: row;

        gap: 4px;

        transition: all 0.5s cubic-bezier(0.18, 0.89, 0.32, 1.71);


        .dot-item {
            width: 3px;
            height: 3px;
        
            background-color: $grey;
            
            border-radius: 999px;
        }
    }
}