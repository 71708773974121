@import "../../css/colors";

.social-links {
    display: flex;
    flex-direction: row;
    
    gap: 1vmin;

    
    .social-item {
        transition: all 0.1s;

        &:hover {
            transform: scale(1.2);
        }
    }
}

.social-icon {
    width: 5.5vmin;
    height: 5.5vmin;

    color: $white;
}

@media (max-width: 480px) {
    .social-icon {
        width: 9vmin;
        height: 9vmin;
    }
}